import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const Logo = props => {
  const { logo } = useStaticQuery(graphql`
    query LogoQuery {
      logo: wp {
        url: siteLogoUrl
      }
    }
  `)
  const classes = useStyles()

  return (
    <img
      alt="Geogo"
      src={logo.url}
      className={classes.logo}
    />
  )
}
const useStyles = makeStyles(theme => ({
  logo: {
    width: 180,
    [theme.breakpoints.down('xs')]: {
      width: 160,
    },
  }
}))
export default Logo;

import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby";

import Logo from "./Logo"

const SiteInfo = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Link to="/">
        <Logo />
      </Link>
    </div>
      
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  siteInfo: {
    paddingLeft: 16,
    color: theme.palette.grey[700],
    margin: "auto 0",
    fontSize: 32,
  }
}))

export default SiteInfo

import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';

import SiteInfo from "./SiteInfo"
import MainMenu from "./MainMenu";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


const Appbar = props => {
  const classes = useStyles()

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar className={classes.appbar}>
          <Container maxWidth={false}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6" className={classes.title}>
                <SiteInfo />
              </Typography>
              <MainMenu />
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    background: "white",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: 96,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      minHeight: 72,
    },
  },
  link: {
    color: theme.palette.grey[700],
    textTransform: "none",
    margin: "0 6px",
    fontSize: 16,
  },
  link2: {
    textTransform: "none",
    fontSize: 15,
  },
}))

export default Appbar;

import { createMuiTheme } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";

import './index.css'; 
import './wp-styles.css'; 

const theme = createMuiTheme({
  palette: {
    primary: {
      ...grey,
      500: "#223547",
    },
    secondary: {
      ...green,
      500: "#006838"
    },
  },
  typography: {
    fontFamily: 'Avenir Next, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, system-ui, -apple-system, sans-serif',
  }
})

export default theme;

import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import withRoot from "../gatsby-theme-material-ui/withRoot"
import Appbar from "./Appbar"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <Appbar />
      <div className={classes.root}>{children}</div>
      <Footer />
    </>
  )
}

const useStyles = makeStyles({
  root: {
    minHeight: "calc( 100vh - 200px )",
    marginTop: 96
  },
})

export default withRoot(Layout)

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import Logo from "./Logo"

import MenuItem from './MenuItem';
import MenuListItem from './MenuListItem';

const MainMenu = props => {
  const { allWpMenu: { menus } } = useStaticQuery(graphql`
    query HeaderMenuQuery {
      allWpMenu(filter: {locations: {eq: PRIMARY}}, limit: 1) {
        menus: edges {
          node {
            name
            menuItems {
              nodes {
                id
                label
                url
                title
                childItems {
                  nodes {
                    id
                    url
                    title
                    label
                    parentId
                  }
                }
                parentId
              }
            }
          }
        }
      }
    }
  `)

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const classes = useStyles();

  const isEmpty = menus.length === 0;
  if (isEmpty) {
    return <span />
  }
  const menuItems = menus[0].node.menuItems.nodes;

  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
    >
      {/* <Logo /> */}
      <List>
        {menuItems.map(menuItem => (
          <MenuListItem key={menuItem.id} menuItem={menuItem} />
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <div className={classes.hideOnMobile}>
        {menuItems.map(menuItem => (
          <MenuItem key={menuItem.id} menuItem={menuItem} />
        ))}
      </div>
      <IconButton onClick={toggleDrawer('right', true)} className={classes.mobileOnly}><MenuIcon /></IconButton>
      <Drawer anchor="right" open={state["right"]} onClose={toggleDrawer("right", false)}>
        {list("right")}
      </Drawer>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  list: {
    paddingTop: 56,
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  mobileOnly: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  hideOnMobile: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  }
}));

export default MainMenu;

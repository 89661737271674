import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from "gatsby";

import LogoWhite from '../assets/images/logo-white.png'
import fbLogo from '../assets/images/facebook-f-brands.png'
import lnLogo from '../assets/images/linkedin-in-brands.png'
import twLogo from '../assets/images/twitter-brands.png'

const Footer = () => {
  const classes = useStyles()

  return (
    <div className={`${classes.root}`}>
      <div className={`alignwider`}>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={3}>
            <img src={LogoWhite} width={128} alt="logo" />
            <Typography variant="body2" className={classes.whiteText3}>© 2021 GEOGO Techsolutions Pvt. Ltd.</Typography>
            <Typography variant="body2" className={classes.whiteText3}>All rights reserved.</Typography>
            <br />
            <br />
            <div className={classes.footFlexRow}>
              <Link to="https://www.linkedin.com/company/geogo-techsolutions" target="_blank">
                <img src={lnLogo} className={classes.socialIcon} width={18} alt="linkedin" />
              </Link>
              <Link to="https://facebook.com/geogo.in" target="_blank">
                <img src={fbLogo} className={classes.socialIcon} width={12} alt="fb" />
              </Link>
              <Link to="https://twitter.com/go_geogo" target="_blank">
                <img src={twLogo} className={classes.socialIcon} width={18} alt="twitter" />
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Typography variant="h6" className={classes.whiteText} gutterBottom>Company</Typography>
            <br />
            <Link to="/company" className={classes.footerLink} gutterBottom>About Us</Link>
            <Link to="/terms" className={classes.footerLink} gutterBottom>Terms of service</Link>
            <Link to="/privacy" className={classes.footerLink} gutterBottom>Privacy policy</Link>
            <Link to="/life-at-geogo" className={classes.footerLink} gutterBottom>Life at Geogo</Link>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Typography variant="h6" className={classes.whiteText} gutterBottom>Services</Typography>
            <br />
            <Link to="/services/digital-product-development" className={classes.footerLink} gutterBottom>Digital Products Engineering</Link>
            <Link to="/services/agile-service-management" className={classes.footerLink} gutterBottom>Agile Service Management</Link>
            <Link to="/services/business-process-automation" className={classes.footerLink} gutterBottom>Business Process Automation</Link>
            <Link to="/services/recruitment-training" className={classes.footerLink} gutterBottom>Recruitment & Training</Link>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Typography variant="h6" component={Link} to="/contact" className={classes.footerLink2} gutterBottom>Contact Us</Typography>
            <br />
            <br />
            <br />
            <Typography variant="body2" className={classes.whiteText2} gutterBottom>Our Offices</Typography>
            <Typography variant="body1" className={classes.whiteText}>Bengaluru, India</Typography>
            <Typography variant="body1" className={classes.whiteText}>Kolkata, India</Typography>
            <Typography variant="body1" className={classes.whiteText}>Texas, USA</Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    backgroundColor: '#12302d',
    paddingTop: 72,
    paddingBottom: 72
  },
  whiteText: {
    color: '#FFF',
    margin: '0 0 12px 0'
  },
  whiteText2: {
    color: 'hsla(0,0%,100%,.5) !important'
  },
  whiteText3: {
    color: 'hsla(0,0%,100%,.5) !important',
    paddingLeft: 10
  },
  footerLink: {
    display: 'block',
    color: 'hsla(0,0%,100%,.7)',
    fontSize: 15,
    marginBottom: 12,
    textDecoration: 'none !important',
    '&:hover': {
      color: '#53c07d',
      // color: 'hsla(0,0%,100%,1)',
    }
  },
  footerLink2: {
    // color: 'hsla(0,0%,100%,.7)',
    color: '#53c07d',
      // textDecoration: 'underline !important',
    '&:hover': {
      color: 'hsla(0,0%,100%,.9)',
      textDecoration: 'underline'
      // color: '#53c07d',
    }
  },
  footFlexRow: {
    display: 'flex',
    alignItems: 'center'
  },
  socialIcon: {
    marginLeft: '12px'
  }
})


export default Footer;
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

const MenuListItem = ({ menuItem }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(null);

  const handleClick = () => {
    setOpen(!open);
  };

  const hasChild = menuItem.childItems.nodes.length > 0

  const renderMenu = (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {menuItem.childItems.nodes.map(menuItem => (
          <ListItem button className={classes.nested} component={Link} to={menuItem.url}>
            <ListItemText primary={menuItem.label} />
          </ListItem>
        ))}
      </List>
    </Collapse>
  );

  if (hasChild) {
    return (
      <>
        <ListItem button onClick={handleClick}>
          <ListItemText primary={menuItem.label} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {renderMenu}
      </>
    )

  }
  if (menuItem.parentId === null) {
    return (
      <ListItem button component={Link} to={menuItem.url}>
        <ListItemText primary={menuItem.label} />
      </ListItem>
    )
  }
  return <span />;
}

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default MenuListItem;

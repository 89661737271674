import React from "react";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link } from "gatsby";

const MainMenuItem = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const { menuItem } = props;

  const hasChild = menuItem.childItems.nodes.length > 0

  const menuId = `menu-dropdown-${menuItem.id}`;

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {menuItem.childItems.nodes.map(menuItem => (
        <MenuItem key={menuItem.id} component={Link} to={menuItem.url}>{menuItem.label}</MenuItem>
      ))}
    </Menu>
  );

  if (hasChild) {
    return (
      <span>
        <Button
          color="primary"
          className={classes.menuItem}
          aria-label="show more"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          endIcon={<DownIcon />}
        >
          {menuItem.label}
        </Button>
        {renderMenu}
      </span>
    )

  }
  if (menuItem.parentId === null) {
    // console.log(menuItem.url)
    return (
      <Button color="primary" variant={menuItem.url === '/contact/' ? "outlined" : "text" } className={classes.menuItem} component={Link} to={menuItem.url}>{menuItem.label}</Button>
    )
  }
  return <span />;
}

const useStyles = makeStyles((theme) => ({
  menuItem: {
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    transition: 'color 200ms',
    paddingLeft: 20,
    paddingRight: 20,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.secondary[500],
    }
  }
}));

export default MainMenuItem;
